<template>
  <div>
    <h1>Sorry!</h1>
    <h3>{{ displayText }}</h3>
  </div>
</template>
<script>

export default {
  props: {
    resource: {
      type: String,
      required: false
    },
    resourceId: {
      type: String,
      required: false
    }
  },
  computed: {
    displayText() {
      let displayText;
      if (this.resource && this.resourceId) {
        displayText = 'The ' + this.resource + ' (' + this.resourceId + ') that you are looking for does not exist';
      } else if (this.resource) {
        displayText = 'The ' + this.resource + ' that you are looking for does not exist';
      } else if (this.resourceId) {
        displayText = 'The resource (' + this.resourceId + ') that you are looking for does not exist';
      } else {
        displayText = 'The resource that you are looking for does not exist';
      }

      return displayText;
    }
  }
};
</script>
